import {useMap, Marker, Popup} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-routing-machine';
import {useCallback, useEffect, useRef, useState} from 'react';
import './style.css';
import {Order} from 'types';
import useStore from '../../store';
import {isString} from 'lodash';
import {addressToString} from 'helpers/address';

const returnIcon = (index = 1, isHistory?: boolean) => {
  return new L.DivIcon({
    className: 'leaflet-div-icon',
    html: `<div class="my-div-icon ${index === 0 ? 'start' : ''} ${
      isHistory && index !== 0 ? 'completed_icon' : ''
    }">${index === 0 ? '' : index}</div>`,
  });
};

type Props = {
  display: boolean;
  orders: Order[];
  isHistory?: boolean;
  start_location: {
    name: string;
    lat: number;
    lng: number;
  };
  end_location: {
    name: string;
    lat: number;
    lng: number;
  };
};

const Routing = ({
  display,
  // isHistory,
  orders,
  start_location,
  end_location,
}: Props) => {
  const map = useMap();
  const directionRef = useRef<any>(null);
  const setDistance = useStore(useCallback((state) => state.setDistance, []));
  const [points, setPoints] = useState<any[]>([]);

  const setupDirections = useCallback(
    async (orders: Order[]) => {
      if (orders.length < 1 || !start_location.name) {
        setDistance(0);
        return;
      }

      const points: any[] = [
        start_location,
        ...orders.map((item) => ({
          ...item,
          lat: !isString(item.delivery_address)
            ? item.delivery_address.latitude
            : 0,
          lng:
            !isString(item.delivery_address) && item.delivery_address.longitude,
          name: isString(item.delivery_address)
            ? item.delivery_address
            : addressToString(item.delivery_address),
        })),
        end_location,
      ];

      let distance = 0;

      points.forEach((item, index) => {
        if (index < points.length - 1) {
          distance += map.distance(
            [points[index].lat, points[index].lng],
            [points[index + 1].lat, points[index + 1].lng]
          );
        } else {
          distance += map.distance(
            [points[index].lat, points[index].lng],
            [points[0].lat, points[0].lng]
          );
        }
      });

      setDistance(distance);

      setPoints(points);

      //@ts-ignore
      directionRef.current = new L.Routing.control({
        waypoints: points.map((item) => L.latLng(item.lat, item.lng)),
        lineOptions: {
          styles: [
            {
              color: '#0072b3',
              opacity: 0.8,
              weight: 4,
            },
          ],
        },
        createMarker: function () {
          return null;
        },
        addWaypoints: false,
        draggableWaypoints: false,
      }).addTo(map);
    },
    [map, start_location, end_location, setDistance]
  );

  useEffect(() => {
    if (directionRef.current) {
      try {
        directionRef.current.hide();
        map?.removeControl(directionRef.current);
      } catch (error) {}

      directionRef.current = null;
    }
    if (display) {
      setupDirections(orders);
    } else {
      if (directionRef.current && map.hasLayer(directionRef.current)) {
        try {
          directionRef.current.hide();
          map?.removeControl(directionRef.current);
          directionRef.current = null;
        } catch (error) {}
      }
    }
  }, [setupDirections, display, orders, map]);

  if (!display || !start_location.name) {
    return null;
  }

  return (
    <>
      {points.map((item, index) => {
        return (
          <Marker
            key={index + 1}
            icon={returnIcon(
              index === points.length - 1 ? 0 : index,
              item.status === 'completed'
            )}
            position={[item.lat, item.lng]}
          >
            {index > 0 && index < points.length - 1 && (
              <Popup>
                <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
                  <span>
                    Customer - {item.customer.user.first_name}{' '}
                    {item.customer.user.last_name} ({index})
                  </span>
                  {/* <span>Group - #{orders[index - 1]?.id}</span> */}
                  <span>Address - {item.name}</span>
                </div>
              </Popup>
            )}
          </Marker>
        );
      })}
    </>
  );
};
export default Routing;
