import {ForwardedRef, forwardRef} from 'react';
import ReactModal, {Props as ReactModalProps} from 'react-modal';
import cn from 'classnames';

import closeIcon from './icons/close.svg';

import styles from './Modal.module.scss';

interface Props extends ReactModalProps {
  onClose?: () => void;
  showCloseButton?: boolean;
  overlayClassName?: string;
  className?: string;
}

const Modal = forwardRef(
  (
    {
      overlayClassName,
      className,
      children,
      onClose,
      showCloseButton = true,
      ...props
    }: Props,
    ref: ForwardedRef<ReactModal>
  ) => {
    return (
      <ReactModal
        ref={ref}
        overlayClassName={cn(styles.overlay, overlayClassName)}
        className={cn(styles.modal, className)}
        shouldCloseOnOverlayClick
        {...props}
      >
        {showCloseButton && (
          <img
            className={styles.close_icon}
            alt="close"
            src={closeIcon}
            onClick={onClose}
          />
        )}
        {children}
      </ReactModal>
    );
  }
);

export default Modal;
