import Select, {Props as SelectProps} from 'react-select';
import {OtcStatus, ScriptStatus, SelectOption, MMScriptStatus} from 'types';

import './ScriptStatusSelect.scss';

export type ScriptStatusOption = SelectOption<ScriptStatus | MMScriptStatus>;

interface Props extends SelectProps<ScriptStatusOption, false> {
  status: ScriptStatus;
  type: string;
  origin?: string;
  isPvfr?: boolean;
  isVerified?: boolean;
}

const MM_OPTIONS: SelectOption<MMScriptStatus>[] = [
  {value: 'incomplete', label: 'Incomplete'},
  {value: 'missing_transfer', label: 'Missing transfer'},
  {value: 'completed', label: 'Unbilled'},
  // {value: 'completed', label: 'Transfer completed'},
  {value: 'billed', label: 'Billed'},
  {value: 'filled', label: 'Filled'},
  // {value: 'otc_status', label: 'OTC'},
  // {value: 'paid', label: 'Paid'},
  {value: 'waiting_for_pickup', label: 'Waiting for pickup'},
  {value: 'ready_for_delivery', label: 'Ready for delivery'},
  {value: 'delivery_partner', label: 'Delivery Partner'},
  {value: 'picked_up', label: 'Picked up'},
  {value: 'delivered', label: 'Delivered'},
  // {value: 'no_script_available', label: 'No script available'},
  {value: 'cancel_script', label: 'Canceled'},
  {value: 'archive', label: 'Archived'},
];

const OTC_OPTIONS_MM: SelectOption<MMScriptStatus>[] = [
  {value: 'otc_status', label: 'OTC CheckOut'},
  {value: 'waiting_for_pickup', label: 'Waiting for pickup'},
  {value: 'ready_for_delivery', label: 'Ready for delivery'},
  {value: 'delivery_partner', label: 'Delivery Partner'},
  {value: 'picked_up', label: 'Picked up'},
  {value: 'delivered', label: 'Delivered'},
  {value: 'cancel_script', label: 'Canceled'},
  {value: 'archive', label: 'Archived'},
];

const OPTIONS: SelectOption<ScriptStatus>[] = [
  {value: 'incomplete', label: 'Incomplete'},
  {value: 'missing_transfer', label: 'Missing transfer'},
  {value: 'request_transfer', label: 'Transfer requested'},
  {value: 'completed', label: 'Transfer completed'},
  {value: 'ready_for_delivery', label: 'Ready for delivery'},
  {value: 'delivered', label: 'Delivered'},
  {value: 'no_script_available', label: 'No script available'},
  {value: 'cancel_script', label: 'Canceled'},
];
const OTC_OPTIONS: SelectOption<OtcStatus>[] = [
  {value: 'incomplete', label: 'Incomplete'},
  {value: 'otc_status', label: 'OTC CheckOut'},
  {value: 'cancel_script', label: 'Canceled'},
  {value: 'ready_for_delivery', label: 'Ready for delivery'},
  {value: 'delivered', label: 'Delivered'},
];

const optionsHandler = (key: string | undefined) => {
  if (key === 'micromerchant') {
    return MM_OPTIONS.filter(
      (item) => item.value !== 'incomplete' && item.value !== 'missing_transfer'
    );
  } else if (key?.startsWith('app')) {
    return OPTIONS.filter(
      (item) =>
        item.value !== 'ready_for_delivery' && item.value !== 'delivered'
    );
  } else {
    return OTC_OPTIONS.filter((item) => item.value !== 'otc_status');
  }
};

function ScriptStatusSelect({
  status,
  isSearchable,
  type,
  origin,
  isPvfr,
  isVerified,
  ...restProps
}: Props) {
  const selectedValue =
    type === 'otc'
      ? origin?.startsWith('app')
        ? OTC_OPTIONS.find((o) => o.value === status)
        : OTC_OPTIONS_MM.find((o) => o.value === status)
      : origin?.startsWith('app')
      ? OPTIONS.find((o) => o.value === status)
      : MM_OPTIONS.find((o) => o.value === status);

  return (
    <Select
      classNamePrefix="ScriptStatusSelect"
      className={status}
      options={
        type === 'otc'
          ? !origin?.startsWith('app')
            ? OTC_OPTIONS_MM
            : OTC_OPTIONS.filter((item) => item.value !== 'otc_status')
          : optionsHandler(origin)
      }
      value={selectedValue}
      isSearchable={false}
      isOptionDisabled={(option) =>
        ((option.value === 'ready_for_delivery' || option.value === 'filled') &&
          type !== 'otc' &&
          (!isPvfr ||
            (option.value !== 'ready_for_delivery' && !isVerified))) ||
        false
      }
      {...restProps}
    />
  );
}

export default ScriptStatusSelect;
