import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import Card from "components/Card";
import Button from "components/Button";
import TextField from "components/TextField";
import CardContent from "components/CardContent";
import Logo from "components/Logo";
import Typography from "components/Typography";

import { validateEmail } from "utils/validators";
import useAuth from "hooks/useAuth";

import styles from "./Signup.module.scss";

interface FormValues {
  email: string;
  password: string;
  passwordConfirmation: string;
}

type Status = "ready" | "error" | "done";

function Signup() {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<Status>("ready");

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormValues>();

  const { register: signup } = useAuth();

  async function submit(data: FormValues) {
    if (data.password !== data.passwordConfirmation) {
      setError("passwordConfirmation", {
        type: "manual",
        message: "Password confirmation doesn't match",
      });

      return;
    }

    setLoading(true);

    try {
      await signup(data.email, data.password);
      setStatus("done");
    } catch {
      setStatus("error");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card className={styles.root}>
      <CardContent>
        <Logo />
        <Typography variant="h2" textAlign="center">
          Sign Up
        </Typography>
        {status === "ready" ? (
          <Fragment>
            <form onSubmit={handleSubmit(submit)}>
              <TextField
                label="Email"
                placeholder="Email"
                type="email"
                error={errors.email?.message}
                {...register("email", {
                  required: "Required",
                  validate: validateEmail,
                })}
              />
              <TextField
                label="Password"
                type="password"
                placeholder="Password"
                error={errors.password?.message}
                {...register("password", {
                  required: "Required",
                  minLength: { value: 6, message: "Too short" },
                })}
              />
              <TextField
                label="Confirm password"
                type="password"
                placeholder="Confirm password"
                error={errors.passwordConfirmation?.message}
                {...register("passwordConfirmation", {
                  required: "Required",
                })}
              />
              <div className={styles.button_container}>
                <Button type="submit" loading={loading}>
                  Sign up
                </Button>
              </div>
            </form>
            <div className={styles.signup}>
              <Typography variant="p">
                Already have an account?{" "}
                <Link className={styles.link} to="/signin">
                  Sign in
                </Link>
              </Typography>
            </div>
          </Fragment>
        ) : status === "done" ? (
          <Typography
            textAlign="center"
            className={styles.message}
            component="p"
            variant="h3"
          >
            To verify your account, click on the confirmation link sent to the
            email provided.
          </Typography>
        ) : (
          <Typography
            textAlign="center"
            className={styles.message}
            component="p"
            variant="h3"
          >
            Something went wrong. Can't tell what exactly.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default Signup;
