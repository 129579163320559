import ReactSelect, {Props as ReactSelectProps} from 'react-select';
import classNames from 'classnames';
import {SelectOption} from 'types';

import './Select.scss';

export interface Props extends ReactSelectProps<SelectOption, false> {
  size: 'sm' | 'md' | 'lg';
  className?: string;
}

const Select = function ({size, className, ...restProps}: Props) {
  return (
    <ReactSelect
      classNamePrefix="Select"
      className={classNames(`Select-${size}`, className)}
      {...restProps}
    />
  );
};

Select.defaultProps = {
  size: 'md',
};

export default Select;
