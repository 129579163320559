import Button from 'components/Button';
import DataTable from 'components/DataTable';
import Input from 'components/Input';
import ListActionsWrapper from 'components/ListActionsWrapper';
import ListTabs from 'components/ListTabs';
import ListWrapper from 'components/ListWrapper';
import Modal from 'components/Modal';
import Pagination from 'components/Pagination';
import ProductImage from 'components/ProductImage';
import ProductModal from 'components/ProductModal';
import SearchInput from 'components/SearchInput';
import Typography from 'components/Typography';
import {ProductTypeFilter} from 'hooks/useProductList';
import React, {useCallback, useMemo, useState} from 'react';
import {DataItem, Product} from 'types';
import styles from './Product.module.scss';
import api from 'api';
import toast from 'react-hot-toast';
import {get} from 'lodash';
import Select from 'components/Select';
import FormActionGroup from 'components/FormActionGroup';

const SCHEMA = [
  {dataKey: 'id', header: 'Product ID', colWidth: '120px'},
  {dataKey: 'image', header: 'Image', colWidth: '100px'},
  {dataKey: 'name', header: 'Name', colWidth: '2fr'},
  {dataKey: 'strength', header: 'Strength'},
  {dataKey: 'description', header: 'Description'},
  {dataKey: 'price', header: 'Price'},
];

const tabs = [
  {title: 'All', value: ''},
  {title: 'OTC', value: 'otc'},
  {title: 'Prescription', value: 'prescription'},
  {title: 'Featured', value: 'featured'},
];

type Props = {
  products: Product[];
  totalPages: number;
  currentPage?: number;
  search?: string;
  refetch: () => void;
  onProductTypeChange: (value: ProductTypeFilter) => void;
  productType?: ProductTypeFilter;
  onSearchChange: (searchValue: string) => void;
};

const Products: React.VFC<Props> = ({
  products,
  productType = '',
  onProductTypeChange,
  totalPages,
  search = '',
  refetch,
  onSearchChange,
  currentPage = 1,
}) => {
  const [visible, setVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product>();

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onSearchChange(e.target.value);
    },
    [onSearchChange]
  );

  const handleSelectProduct = useCallback(
    (dataItem: DataItem) => {
      const product = products.find((item) => item.id === dataItem.id);
      setSelectedProduct(product);
    },
    [products]
  );

  const data = useMemo(() => {
    return products.map((product) => ({
      id: product.id,
      image: <ProductImage product={product} />,
      name: product.name,
      strength: product.strength?.replaceAll('/', ' / '),
      description: product.description,
      price: product.price ? '$' + product.price : '',
    }));
  }, [products]);

  const onChangeTabs = useCallback(
    (nextValue: string) => {
      onProductTypeChange(nextValue as ProductTypeFilter);
    },
    [onProductTypeChange]
  );

  const deleteProduct = async () => {
    try {
      await api.delete(`/products/${selectedProduct?.id}`);
      refetch();
      setSelectedProduct(undefined);
    } catch (error) {
      toast.error(get(error, 'message', ''));
    }
  };

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant="h2">All products</Typography>
        <Button onClick={() => setVisible(true)} color="blue">
          Add Product
        </Button>
      </div>
      <ListWrapper>
        <ListTabs
          tabs={tabs}
          isProductsPage={true}
          activeTabValue={productType}
          onChange={onChangeTabs}
        />
        <ListActionsWrapper>
          <SearchInput
            placeholder="Search"
            onChange={handleSearchChange}
            value={search}
          />
        </ListActionsWrapper>
        <DataTable
          data={data}
          schema={SCHEMA}
          selectable={false}
          onClick={handleSelectProduct}
        />
        <Pagination
          basePath="/products"
          page={currentPage}
          total={totalPages}
          setCurrentPage={() => {}}
        />
      </ListWrapper>
      <ProductModal
        isOpen={!!selectedProduct}
        product={selectedProduct}
        onDelete={deleteProduct}
        onClose={() => setSelectedProduct(undefined)}
      />
      <NewProduct
        visible={visible}
        onClose={() => setVisible(false)}
        onSuccess={() => {
          refetch();
        }}
      />
    </>
  );
};

type ProductProps = {
  visible: boolean;
  onClose: () => void;
  onSuccess: (data: Product) => void;
};

export const NewProduct = ({visible, onClose, onSuccess}: ProductProps) => {
  const [price, setPrice] = useState('');
  const [name, setName] = useState('');
  const [offerType, setOfferType] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<any>(null);
  const [strength, setStrength] = useState<any>(null);

  const onSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('name', name);
    formData.append('offering_type', offerType);
    formData.append('price', price);
    formData.append('description', description);
    formData.append('strength', strength);
    formData.append('image.file', image);

    api
      .post('products/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        onSuccess(res.data);
        setPrice('');
        setName('');
        setDescription('');
        setOfferType('');
        setStrength('');
        setImage(null);
        onClose();
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      isOpen={visible}
      onClose={onClose}
      className={styles.list_wrapper}
      onRequestClose={onClose}
    >
      <Typography variant="h2">Add new product</Typography>
      <div className={styles.label}>Image:</div>
      <ProductImage
        size="medium"
        onChange={(value) => {
          setImage(value);
        }}
      />
      <div className={styles.label}>Name:</div>
      <Input
        value={name}
        onChange={(event) => {
          setName(event.target.value);
        }}
        placeholder="Name"
        className={styles.root}
      />
      <div className={styles.label}>Strength</div>
      <Input
        value={strength}
        onChange={(event) => setStrength(event.target.value)}
        placeholder="Strength"
      />
      <div className={styles.label}>Description:</div>
      <Input
        value={description}
        onChange={(event) => {
          setDescription(event.target.value);
        }}
        placeholder="Description"
        className={styles.root}
      />
      <div className={styles.label}>Price:</div>
      <Input
        onChange={(value) => {
          let val = value.target.value;
          let dec = '';
          if (val.split('.').length > 1) {
            dec = '.' + val.split('.')[1].replace(/\D/g, '');
            val = val.split('.')[0];
          }
          setPrice(
            val.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ') +
              `${dec}`
          );
        }}
        value={price}
        placeholder="Price"
        className={styles.root}
      />
      <div className={styles.label}>Click to select</div>
      <Select
        onChange={(val) => {
          setOfferType(String(val?.value));
        }}
        options={[
          {
            label: 'Prescription',
            value: 'true',
          },
          {
            label: 'OTC',
            value: 'false',
          },
        ]}
      />
      <FormActionGroup className={styles.buttonWrap}>
        <Button onClick={onClose} component="a" color="gray">
          Cancel
        </Button>
        <Button loading={loading} onClick={onSubmit} color="green">
          Add Product
        </Button>
      </FormActionGroup>
    </Modal>
  );
};

export default Products;
