import {UpdateProductDto} from 'dto/update-product.dto';
import React, {useCallback} from 'react';
import {useQueryClient} from 'react-query';
import {ProductService} from 'services/product.service';
import ProductModal, {ProductModalProps} from './ProductModal';

type Props = Omit<ProductModalProps, 'onSubmit'>;

const ProductModalContainer: React.VFC<Props> = (props) => {
  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async (data: UpdateProductDto) => {
      if (!props.product) {
        return;
      }
      const productService = new ProductService();
      await productService.updateProduct(props.product.id, data);
      queryClient.invalidateQueries('products');
      props.onClose();
    },
    [props, queryClient]
  );
  return <ProductModal {...props} onSubmit={handleSubmit} />;
};

export default ProductModalContainer;
