import {Address} from 'types';

export function addressToString(address: Address) {
  return (
    [
      `${address.address_line_1 || ''} ${address.address_line_2 ?? ''}`.trim(),
      address.city ?? '',
      address.state ?? '',
      address.zipcode ?? '',
    ]
      .filter((item) => !!item)
      .join(', ') || '-'
  );
}
