// import {Controller} from 'react-hook-form';

import Typography from 'components/Typography';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
// import SelectField from 'components/SelectField';
import ListWrapper from 'components/ListWrapper';
import ClickablePagination from 'components/ClickablePagination';

// import states from 'constants/states';
import {validateEmail} from 'utils/validators';
import syncIcon from './sync.svg';
import shareIcon from './share.svg';
import useUserForm from './useUserForm';
import UserAddresses from './UserAddresses';
import CardInformation from './CardInformation/CardInformation';
import DataTable from 'components/DataTable';
import {DataItem, DataTableSchemaItem, SelectOption} from 'types';
import {useHistory} from 'react-router-dom';
import {Key, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import api from 'api';
import toast from 'react-hot-toast';
import ListTabs from './ListTabs';
import {get} from 'lodash';
import Modal from 'components/Modal';
import styles from './UserForm.module.scss';
import Select from 'components/Select';
import Spinner from 'components/Spinner';

// import styles from "./UserForm.module.scss";

const SCHEMA: DataTableSchemaItem[] = [
  {dataKey: 'number', header: 'Order #', sortable: false},
  {dataKey: 'rx_no', header: 'Rx #', sortable: false},
  {dataKey: 'sync_time', header: 'Sync time', sortable: true},
  {dataKey: 'is_paid', header: 'Paid', sortable: true},
  {dataKey: 'status', header: 'Status', sortable: true},
  // {dataKey: 'refill_no', header: 'Refill #', sortable: false},
  // {dataKey: 'view', header: '', align: 'right'},
  {dataKey: 'drug_name', header: 'Drug name', sortable: false},
];

const MM_SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'transaction_id',
    header: 'ID',
    sortable: true,
    index: 0,
  },
  {dataKey: 'amount', header: 'Amount', sortable: true, index: 1},
  {
    dataKey: 'transaction_date',
    header: 'Date',
    sortable: false,
    align: 'center',
    index: 2,
  },
  {
    dataKey: 'transaction_origin',
    header: 'Source',
    align: 'center',
    sortable: true,
    index: 3,
  },
  {dataKey: 'list_rx', header: 'List of RXs', sortable: false, index: 5},
  {dataKey: 'list_orders', header: 'List of Orders', sortable: false, index: 6},
];

function UserForm() {
  const {
    userId,
    addresses,
    setAddressesForUpdate,
    submitting,
    errors,
    register,
    handleSubmit,
    requests,
    transactions,
    isLoading,
    orderLoading,
    transactionsLoading,
    refetch,
    totalPages,
    currentPage,
    setCurrentPage,
    status,
    setStatus,
    cards,
    filledOrders,
  } = useUserForm();

  const [selectCardModal, setSelectCardModal] = useState(false);
  const [accessCardModal, setAccessCardModal] = useState(false);
  const [addressModalVisible, setAddressModalVisible] = useState(false);

  const [cardId, setCardIs] = useState<SelectOption>();

  const history = useHistory();
  const isDetails = useRef(true);

  useEffect(() => {
    if (cards.length > 0) {
      setCardIs({
        value: cards[0].custom_uuid,
        label: `**** **** **** ${get(cards[0], 'last4', '')} - ${
          cards[0].cardholder_name
        }`,
      });
    }
  }, [cards]);

  const handleNavigateRequest = useCallback(
    (row: DataItem) => {
      history.push(`/requests/${row.id}`);
    },
    [history]
  );
  const handleNavigateTransaction = useCallback(
    (row: DataItem) => {
      if (get(row, 'service_name', '') === 'CreditSale') {
        history.push(`/transactions/${row.id}`);
      }
    },
    [history]
  );

  const syncHandler = async () => {
    try {
      await api.post(`/customers/${userId}/sync-with-mm/`);
      toast.success('Patient was synced successfully');
      refetch();
    } catch (error) {}
  };

  const sendAppLink = async () => {
    try {
      await api.post(`/customers/${userId}/send-sms/`);
    } catch (error) {}
  };

  const tabs = useMemo(function () {
    return [
      {title: 'Details', value: ''},
      {title: 'Addresses', value: 'address'},
      {title: 'Cards', value: 'cards'},
      {title: 'Orders', value: 'orders'},
      {title: 'Transactions', value: 'transactions'},
    ];
  }, []);

  const onCharge = async () => {
    // if (cards.length < 1) {
    //   toast.error('Please add billing card to user first');
    //   return;
    // }
    // if (cards.length > 1) {
    //   setSelectCardModal(true);
    //   return;
    // }
    setAccessCardModal(true);
  };

  const submitSelectedCard = async () => {
    try {
      await api.post('/payment/charge-all/', {
        customer: userId,
        payment_method_uuid: cardId?.value,
      });
      setAccessCardModal(false);
      // setSelectCardModal(false);
      toast.success('Success');
    } catch (error) {
      toast.error('Error try again');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <PageHeader>
          <Typography variant="h2">
            {userId ? 'Edit Patient' : 'New Patient'}
          </Typography>
          <div style={{display: 'flex', gap: 10}}>
            <Button onClick={syncHandler} color="blue">
              Sync patient profile
              <img alt="sync" style={{marginLeft: 10}} src={syncIcon} />
            </Button>
            <Button onClick={onCharge} color="green">
              Charge All
            </Button>
          </div>
        </PageHeader>
        <ListTabs onChange={setStatus} activeTabValue={status} tabs={tabs} />
        {isLoading && <Spinner className={styles.loader} />}
        <FormGroup style={{marginBottom: 20}} columns={2}>
          {status === '' && (
            <ListWrapper
              style={{
                height: 480,
                paddingLeft: 20,
                display: 'flex',
                paddingTop: 20,
                paddingRight: 20,
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="h3">Details</Typography>
                  <Button onClick={sendAppLink} color="green">
                    Send App Link
                    <img alt="sync" style={{marginLeft: 10}} src={shareIcon} />
                  </Button>
                </div>
                <FormGroup columns={2}>
                  <TextField
                    label="First name"
                    placeholder="First name"
                    {...register('first_name', {required: 'Required'})}
                    error={errors.first_name?.message}
                  />
                  <TextField
                    label="Last name"
                    placeholder="Surname"
                    {...register('last_name', {required: 'Required'})}
                    error={errors.last_name?.message}
                  />
                </FormGroup>

                <FormGroup columns={1}>
                  <TextField
                    label="Email address"
                    placeholder="Email address"
                    {...register('email', {
                      required: 'Required',
                      validate: validateEmail,
                    })}
                    error={errors.email?.message}
                  />
                </FormGroup>

                <FormGroup columns={1}>
                  <TextField
                    label="Phone number"
                    placeholder="Phone number"
                    {...register('phone', {
                      required: 'Required',
                    })}
                    error={errors.phone?.message}
                  />
                </FormGroup>

                <FormGroup columns={1}>
                  <TextField
                    label="Date of birth"
                    placeholder="mm/dd/yyyy"
                    {...register('birthday', {
                      required: 'Required',
                    })}
                    error={errors.phone?.message}
                  />
                </FormGroup>
              </div>
              <Button
                style={{
                  marginLeft: 'auto',
                  marginBottom: 10,
                  minHeight: 40,
                  marginTop: 20,
                }}
                type="submit"
                onClick={() => {
                  isDetails.current = true;
                }}
                color="green"
                loading={isDetails.current && submitting}
              >
                Save details
              </Button>
            </ListWrapper>
          )}
          {status === 'cards' && (
            <ListWrapper style={{padding: 0}}>
              <CardInformation
                setAddressModalVisible={setAddressModalVisible}
                addresses={addresses}
                userId={userId}
              />
            </ListWrapper>
          )}

          <UserAddresses
            addressModalVisible={addressModalVisible}
            setAddressModalVisible={setAddressModalVisible}
            addresses={addresses}
            userId={Number(userId)}
            onSave={() => {
              isDetails.current = false;
            }}
            refetch={refetch}
            isShow={status === 'address'}
            deleting={!isDetails.current && submitting}
            onChange={setAddressesForUpdate}
          />
        </FormGroup>
      </form>

      {status === 'orders' && (
        <DataTable
          schema={SCHEMA}
          data={requests}
          // sortBy={sortBy}
          // sortOrder={sortOrder}
          onClick={handleNavigateRequest}
          // onSort={onSort}
          loading={orderLoading}
        />
      )}
      {status === 'transactions' && (
        <DataTable
          schema={MM_SCHEMA}
          data={transactions}
          // sortBy={sortBy}
          // sortOrder={sortOrder}
          onClick={handleNavigateTransaction}
          // onSort={onSort}
          loading={transactionsLoading}
        />
      )}
      {(status === 'transactions' || status === 'orders') && totalPages > 1 && (
        <ClickablePagination
          page={currentPage}
          total={totalPages}
          setCurrentPage={setCurrentPage}
        />
      )}
      <Modal
        isOpen={selectCardModal}
        onClose={() => setSelectCardModal(false)}
        className={styles.list_wrapper}
      >
        <Typography component="p" className={styles.modalDesc} variant="h2">
          Select Card to Charge:
        </Typography>
        <div className={styles.optionWrapper}>
          {cards.map((item: any, index: Key | null | undefined) => {
            return (
              <label key={index} className={styles.radioOption}>
                <input
                  type="radio"
                  name="radio"
                  defaultChecked={cardId === item.custom_uuid}
                  onClick={() => setCardIs(item.custom_uuid)}
                />
                {/* <div className={styles.column}> */}
                <span className={styles.radioButtonText}>
                  {get(item, 'cardholder_name', '')}
                </span>
                <p
                  style={{marginLeft: 'auto'}}
                  className={styles.radioButtonText}
                >
                  **** **** **** {get(item, 'last4', '')}
                </p>
                {/* </div> */}
              </label>
            );
          })}
        </div>
        <div className={styles.actions}>
          <Button
            color="outlined"
            size="md"
            onClick={() => setSelectCardModal(false)}
          >
            Close
          </Button>
          <Button
            color="green"
            onClick={() => setAccessCardModal(true)}
            size="md"
          >
            Submit
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={accessCardModal}
        onClose={() => setAccessCardModal(false)}
        className={styles.list_wrapper}
      >
        <Typography component="p" className={styles.modalDesc} variant="h2">
          Are you sure you want to charge?
        </Typography>
        <Typography
          component="p"
          style={{marginTop: 25, marginBottom: 10}}
          variant="h4"
        >
          Card:
        </Typography>
        <Select
          className={styles.selectCon}
          isSearchable={false}
          onChange={(val: any) => setCardIs(val)}
          options={cards.map((item) => ({
            value: item.custom_uuid,
            label: `**** **** **** ${get(item, 'last4', '')} - ${
              item.cardholder_name
            }`,
          }))}
          value={cardId}
          placeholder={
            <span className="select-placeholder-text">Select card</span>
          }
        />

        {/* <div className={styles.card}>
          <div className={styles.item_header}>
            <div>{get(selectedCard, 'cardholder_name', '')}</div>
          </div>
          <div className={styles.row}>
            <div>**** **** **** {get(selectedCard, 'last4', '')}</div>
            <div>
              Expiration: {get(selectedCard, 'exp_month', '')}/
              {get(selectedCard, 'exp_year', '')}
            </div>
          </div>
        </div> */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 30,
            marginTop: 30,
          }}
        >
          <Typography component="p" style={{marginTop: 25}} variant="h4">
            Orders:
          </Typography>
          <Typography component="p" style={{marginTop: 25}} variant="h4">
            Amount Due: $
            {filledOrders
              .reduce(
                (pr: number, curr: any) => pr + Number(curr.amount_due),
                0
              )
              .toFixed(2)}
          </Typography>
        </div>

        <DataTable
          containerStyle={styles.orders}
          wrapperStyle={styles.ordersWrapper}
          schema={[
            {dataKey: 'number', header: 'Order #', sortable: false},
            {dataKey: 'drug_name', header: 'Drug name', sortable: false},
            {dataKey: 'amount_due', header: 'Amount due', sortable: false},
          ]}
          data={filledOrders.map((item) => ({
            ...item,
            amount_due: '$' + item.amount_due,
          }))}
        />
        <div className={styles.actions}>
          <Button
            color="outlined"
            size="md"
            onClick={() => setAccessCardModal(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            color="green"
            disabled={!cardId?.value}
            onClick={submitSelectedCard}
            size="md"
          >
            Submit
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default UserForm;
