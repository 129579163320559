import { useCallback, useEffect, useMemo, useState } from "react";
import authContext from "contexts/authContext";

import api from "api";

import { User } from "types";
import { AuthService } from "services/auth.service";

interface Props {
  children: React.ReactNode;
}

interface RegisterResponse {
  message: string;
  email: string;
  token: string;
}

// interface AuthResponse {
//   token: string;
// }

function AuthProvider({ children }: Props) {
  const [currentUser, setCurrentUser] = useState<User | undefined>();
  const [token, setToken] = useState<string>();
  const [loading, setLoading] = useState(true);

  const componentDidMount = useCallback(
    async function () {
      try {
        const authService = new AuthService();
        setLoading(true);
        const { data } = await authService.getProfile();
        setCurrentUser({ ...data });
      } catch (error) {
        // localStorage.removeItem("token");
        setToken(undefined);
        setCurrentUser(undefined);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(
    function () {
      componentDidMount();
    },
    [token, componentDidMount]
  );

  const setup = useCallback(
    async function (token: string) {
      localStorage.setItem("token", token);
      setToken(token);
    },
    []
  );

  const register = useCallback(
    async function (email: string, password: string) {
      try {
        const {
          data: { token },
        } = await api.post<RegisterResponse>("/users/register/", {
          email,
          password,
          role: "tech",
        });

        setup(token);
      } catch {
        throw Error("Registration error");
      }
    },
    [setup]
  );

  const login = useCallback(
    async function (email: string, password: string) {
      const authService = new AuthService();
      const {
        data: { token },
      } = await authService.login(email, password);
      await setup(token);
    },
    [setup]
  );

  const logout = useCallback(async function () {
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    setToken(undefined);
    setCurrentUser(undefined);
  }, []);

  const auth = useMemo(
    function () {
      return { currentUser, token, loading, register, login, logout };
    },
    [currentUser, token, loading, register, login, logout]
  );

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default AuthProvider;
