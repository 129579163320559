import Button from 'components/Button';
import FormGroup from 'components/FormGroup';
import Modal from 'components/Modal';
import ProductImage from 'components/ProductImage';
import TextField from 'components/TextField';
import Typography from 'components/Typography';
import {UpdateProductDto} from 'dto/update-product.dto';
import {pick} from 'lodash';
import React, {useEffect} from 'react';
import {Controller, useForm, useFormState} from 'react-hook-form';
import {Product} from 'types';

import styles from './ProductModal.module.scss';

export type ProductModalProps = {
  product?: Product;
  isOpen?: boolean;
  onClose: () => void;
  onDelete: () => void;
  onSubmit: (values: UpdateProductDto) => Promise<any>;
};

const ProductModal: React.VFC<ProductModalProps> = ({
  product,
  onClose,
  onDelete,
  onSubmit,
  isOpen = false,
}) => {
  const {handleSubmit, control, register, reset} = useForm();
  const {isSubmitting} = useFormState({control});

  useEffect(() => {
    if (product) {
      reset({
        ...pick(product, 'name', 'strength', 'description', 'price'),
      });
    }
  }, [product, reset]);

  if (!product) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.header}>
        <div className={styles.header__title}>
          <Typography variant="h2">{product.name}</Typography>
        </div>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="image"
          control={control}
          render={({field}) => (
            <ProductImage
              product={product}
              size="medium"
              onChange={(value) => field.onChange({target: {value}})}
            />
          )}
        />
        <FormGroup>
          <TextField
            defaultValue={product.name}
            label="Name"
            {...register('name')}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            defaultValue={product.strength}
            label="Strength"
            {...register('strength')}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            defaultValue={product.description}
            label="Description"
            {...register('description')}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            type="number"
            min={0}
            step={0.01}
            defaultValue={product.price}
            label="Price"
            {...register('price')}
          />
        </FormGroup>
        <div className={styles.actions}>
          <Button color="danger" size="md" onClick={onDelete}>
            Delete
          </Button>
          <Button type="submit" color="green" size="md" loading={isSubmitting}>
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ProductModal;
