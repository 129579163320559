const addressTypes = [
  {
    label: 'Delivery',
    value: 'shipping',
  },
  {
    label: 'Billing',
    value: 'billing',
  },
  {
    label: 'Both',
    value: 'both',
  },
];

export default addressTypes;
