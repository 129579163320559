import React, {Fragment, useMemo, useState} from 'react';
import classNames from 'classnames';

import Typography from 'components/Typography';
import Select from 'components/Select';
// import Input from 'components/Input';

import {SelectOption, Vehicle} from 'types';

import truck from '../../icons/truck.svg';
import vehicle from '../../icons/vehicle.svg';
import location from '../../icons/location.svg';

import styles from '../../DeliveryPlanner.module.scss';
import localStyles from './AssignedHeader.module.scss';
import {SingleValue} from 'react-select';
import Button from 'components/Button';
import {get} from 'lodash';
import {GooglePlaceAutoComplete} from 'components/GooglePlaceAutoComplete';
import DateTimePicker from 'components/DateTimePicker';
import {parseJSON} from 'date-fns';

interface Props {
  currentVehicle?: Vehicle;
  vehicles?: Vehicle[];
  startLocation: {
    name: string;
    lat: number;
    lng: number;
  };
  endLocation: {
    name: string;
    lat: number;
    lng: number;
  };
  onChange: (
    startLocation: {
      name: string;
      lat: number;
      lng: number;
    },
    endLocation: {
      name: string;
      lat: number;
      lng: number;
    },
    currentVehicle?: Vehicle
  ) => void;
  onChangeStartTime: (time: any) => void;
  recalculate: () => void;
  start_time?: string | null;
  routes?: number;
  roundedTime: Date | undefined;
}

function AssignedHeader({
  currentVehicle,
  vehicles,
  startLocation,
  endLocation,
  onChange,
  start_time,
  onChangeStartTime,
  routes,
  roundedTime,
  recalculate,
}: Props) {
  const [expanded, setExpanded] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState<number>(
    currentVehicle ? currentVehicle?.id : 0
  );
  const [endpoints, setEndpoints] = useState({
    startLocation,
    endLocation,
  });

  const options = useMemo(
    function () {
      return vehicles?.map((v) => ({
        label: (
          <div
            aria-disabled={!get(v, 'is_available', true)}
            className={localStyles.option}
          >
            <img alt="truck" src={vehicle} />
            {v.nickname}
            {!get(v, 'is_available', true) && (
              <div className={localStyles.driverStatusWrap}>
                <p className={localStyles.driverStatus}>busy</p>
              </div>
            )}
          </div>
        ),
        value: v.id,
      }));
    },
    [vehicles]
  );

  function onChangeVehicle(newValue: SingleValue<SelectOption>) {
    setSelectedVehicleId(Number(newValue?.value));
  }

  // useEffect(
  //   function () {
  //     if (currentVehicle?.id) {
  //       setSelectedVehicleId(currentVehicle?.id);
  //     }
  //     if (startLocation.name) {
  //       setEndpoints((pr) => ({...pr, startLocation}));
  //     }
  //     if (endLocation.name) {
  //       setEndpoints((pr) => ({
  //         ...pr,
  //         endLocation,
  //       }));
  //     }
  //   },
  //   [currentVehicle, startLocation, endLocation]
  // );

  return (
    <Fragment>
      <div className={classNames(styles.panel__header)}>
        <div className={styles.panel__vehicle_title}>
          {!currentVehicle?.nickname && (
            <p className={styles.panel__vehicle}>
              <img alt="truck icon" src={truck} />
              {currentVehicle?.nickname || 'not assigned'}
            </p>
          )}
          {!!currentVehicle && (
            <p className={styles.panel__endpoints}>
              <Typography component="span" variant="p">
                Router: {routes} Deliveries
              </Typography>
              {/* <Typography component="span" variant="a">
                {startLocation || 'no start location'}
              </Typography>{' '}
              to{' '}
              <Typography component="span" variant="a">
                {endLocation || 'no end location'}
              </Typography> */}
            </p>
          )}
        </div>
        <span
          className={styles.panel__change_vehicle}
          onClick={() => setExpanded((prev) => !prev)}
        >
          Assign
        </span>
      </div>
      <div
        className={classNames(localStyles.form, {
          [localStyles['--expanded']]: expanded,
        })}
      >
        {expanded && (
          <div className={localStyles.form__content}>
            <div className={localStyles.formRow}>
              <div className={localStyles.label}>Vehicle:</div>
              <Select
                size="sm"
                options={options}
                className={localStyles.select}
                value={options?.find((o) => o.value === selectedVehicleId)}
                onChange={onChangeVehicle}
              />
            </div>
            <div className={localStyles.formRow}>
              <div className={localStyles.label}>Start loc:</div>
              <div className={localStyles.root}>
                <div className={localStyles.icon_wrapper}>
                  <img alt="location" src={location} />
                </div>
                <GooglePlaceAutoComplete
                  wrapperClass={localStyles.wrapper}
                  style={{paddingLeft: 22}}
                  defaultValue={`${endpoints.startLocation.name}`}
                  placeholder="Search address"
                  onSelectPlace={(placeDetail) => {
                    onChange(
                      {
                        name: placeDetail.fullAddress,
                        lat: placeDetail.lat,
                        lng: placeDetail.lon,
                      },
                      endpoints.endLocation,
                      vehicles?.find((v) => v.id === selectedVehicleId)
                    );
                    setTimeout(() => {
                      recalculate();
                    }, 300);
                    setEndpoints((pr) => ({
                      ...pr,
                      startLocation: {
                        name: placeDetail.fullAddress,
                        lat: placeDetail.lat,
                        lng: placeDetail.lon,
                      },
                    }));
                  }}
                  options={{
                    types: ['address'],
                  }}
                />
              </div>
            </div>
            {/* <Input
              name="startLocation"
              size="sm"
              icon={<img alt="location" src={location} />}
              value={endpoints.startLocation}
              onChange={onChangeEndpoints}
            /> */}
            <div className={localStyles.formRow}>
              <div className={localStyles.label}>End loc:</div>
              {/* <Input
              name="endLocation"
              size="sm"
              icon={<img alt="location" src={location} />}
              value={endpoints.endLocation}
              onChange={onChangeEndpoints}
            /> */}
              <div className={localStyles.root}>
                <div className={localStyles.icon_wrapper}>
                  <img alt="location" src={location} />
                </div>
                <GooglePlaceAutoComplete
                  style={{paddingLeft: 22}}
                  wrapperClass={localStyles.wrapper}
                  placeholder="Search address"
                  defaultValue={`${endpoints.endLocation.name}`}
                  onSelectPlace={(placeDetail) => {
                    onChange(
                      endpoints.startLocation,
                      {
                        name: placeDetail.fullAddress,
                        lat: placeDetail.lat,
                        lng: placeDetail.lon,
                      },
                      vehicles?.find((v) => v.id === selectedVehicleId)
                    );
                    setTimeout(() => {
                      recalculate();
                    }, 300);
                    setEndpoints((pr) => ({
                      ...pr,
                      endLocation: {
                        name: placeDetail.fullAddress,
                        lat: placeDetail.lat,
                        lng: placeDetail.lon,
                      },
                    }));
                  }}
                  options={{
                    types: ['address'],
                  }}
                />
              </div>
            </div>
            <div className={localStyles.formRow}>
              <div className={localStyles.label}>Time:</div>
              <div className={localStyles.root}>
                <div className={localStyles.calendarRoot}>
                  <DateTimePicker
                    minTime={roundedTime ?? roundedTime}
                    maxTime={
                      roundedTime
                        ? new Date(new Date().setHours(23, 59, 59))
                        : undefined
                    }
                    minDate={new Date()}
                    value={start_time ? parseJSON(start_time) : undefined}
                    onChange={onChangeStartTime}
                  />
                </div>
              </div>
            </div>
            <div className={localStyles.actions}>
              <Button
                color="outlined"
                size="sm"
                onClick={() => setExpanded(false)}
              >
                Cancel
              </Button>
              <Button
                color="green"
                size="sm"
                onClick={() => {
                  onChange(
                    endpoints.startLocation,
                    endpoints.endLocation,
                    vehicles?.find((v) => v.id === selectedVehicleId)
                  );
                  setExpanded(false);
                }}
              >
                Update
              </Button>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default AssignedHeader;
