import Typography from 'components/Typography';
import Card from 'components/Card';
import CardContent from 'components/CardContent';

import styles from '../RequestDetails.module.scss';
import { RequestDetails } from 'types/request';
//import insuranceProviderMap from 'types/insurance-provider.map';

interface Props {
  request: RequestDetails;
  showInsurance: () => void;
}

function DoctorDetails({ request, showInsurance }: Props) {
  const { doctor_name, doctor_phone, insurance } = request || {};
  return (
    <Card>
      <CardContent>
        <div className={styles.info}>
          <Typography component="p" variant="label">
            Prescriber
          </Typography>
          <Typography component="p" variant="h3">
            {doctor_name || '-'}
          </Typography>
          <Typography component="p" variant="h3">
            {doctor_phone || ''}
          </Typography>
        </div>
      </CardContent>
      <CardContent>
        <div className={styles.info}>
          <Typography component="p" variant="label">
            Insurance
          </Typography>
          {insurance && (
            <div className={styles.insurance}>
              {/* {insurance.provider
                // && (
                // <img
                //   className={styles.insurance__logo}
                //   src={insuranceProviderMap[insurance.provider].logo}
                //   alt={insurance.provider}
                // />
                // )
              } */}
              <Typography component="p" variant="h4">
                {insurance.provider
                  // ? insuranceProviderMap[insurance.provider].title
                  // : '-'
                }
              </Typography>
              <div className={styles.insurance__edit_wrapper}>
                <Typography
                  className={styles.insurance__edit}
                  component="span"
                  variant="a"
                  onClick={showInsurance}
                >
                  See card
                </Typography>
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

export default DoctorDetails;
