import {AxiosResponse} from 'axios';
import {UpdateProductDto} from 'dto/update-product.dto';
import {ProductFilter} from 'hooks/useProductList';
import {omit} from 'lodash';
import {PaginatedResponse, Product} from 'types';
import {ApiClient} from './api-client';

export class ProductService extends ApiClient {
  async getProducts(
    filter: ProductFilter
  ): Promise<AxiosResponse<PaginatedResponse<Product>>> {
    let urlSuffix = '';
    switch (filter.productType) {
      case 'featured':
        urlSuffix = 'prescriptions/featured/';
        break;
      case 'otc':
        urlSuffix = 'otc/';
        break;
      case 'prescription':
        urlSuffix = 'prescriptions/';
        break;
      case '':
        urlSuffix = '';
        break;
    }
    return this.get(`/products/${urlSuffix}`, {
      ...omit(filter, 'page', 'productType'),
      offset: (filter.page || 0) * 20,
    });
  }

  async updateProduct(id: number, dto: UpdateProductDto) {
    const formData = new FormData();
    Object.keys(omit(dto, 'image')).forEach((key) => {
      const objectKey = key as keyof UpdateProductDto;
      const objectValue = dto[objectKey];
      if (typeof objectValue === 'undefined') {
        formData.append(key, '');
      } else {
        formData.append(key, objectValue);
      }
    });
    if (dto.image) {
      formData.append('image.file', dto.image);
    }
    return this.put(`/products/${id}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
