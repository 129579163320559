import {format, parse} from 'date-fns';
import Typography from 'components/Typography';
import Card from 'components/Card';
import CardContent from 'components/CardContent';

import {Customer} from 'types';
import {getFullName} from 'helpers/user';

import IdentificationCard from './icons/IdentificationCard.svg';

import styles from '../RequestDetails.module.scss';
import {Link} from 'react-router-dom';
import {Address} from './DeliveryAdressType';
import ArrowBottomIcon from './icons/ArrowBottomIcon';
import {get} from 'lodash';

interface Props {
  pharmacy: {
    name: string;
    phone: string;
    address: string;
  };
  isOrder: boolean;
  customer: Customer;
  deliveryData: Address;
  modalHandler: () => void;
}

function CustomerDetails({
  pharmacy,
  customer,
  deliveryData,
  isOrder,
  modalHandler,
}: Props) {
  return (
    <Card>
      {!isOrder && (
        <CardContent dark>
          <div className={styles.info}>
            <Typography component="p" variant="label">
              Pharmacy
            </Typography>
            <Typography component="p" variant="h2">
              {pharmacy?.name || '-'}
            </Typography>
          </div>
          <div className={styles.info}>
            <Typography component="p" variant="label">
              Phone Number
            </Typography>
            <Typography component="p" variant="h3">
              {pharmacy?.phone || '-'}
            </Typography>
          </div>
          <div className={styles.info}>
            <Typography component="p" variant="label">
              Address
            </Typography>
            <Typography component="p" variant="h3">
              {pharmacy?.address || '-'}
            </Typography>
          </div>
        </CardContent>
      )}
      <CardContent>
        <div className={styles.info}>
          <div className={styles.row}>
            <Typography component="p" variant="label">
              Patient
            </Typography>
            {!!get(customer, 'patient_no', null) && (
              <Typography className={styles.text} component="p" variant="label">
                #{get(customer, 'patient_no', null)}
              </Typography>
            )}
          </div>
          <Typography className={styles.fullname} component="p" variant="h2">
            {getFullName(customer.user)}
          </Typography>
          <Link to={`/settings/users/${customer.id}/edit`}>
            <img
              className={styles.identification_card}
              alt="Identification card"
              src={IdentificationCard}
            />
          </Link>
        </div>
        <div className={styles.horizont}>
          <div>
            <Typography component="p" variant="label">
              Phone Number
            </Typography>
            <Typography component="p" variant="h3">
              {customer.user.phone || '-'}
            </Typography>
          </div>
          <div className={styles.info}>
            <Typography component="p" variant="label">
              Date of birth
            </Typography>
            <Typography className={styles.dobText} component="p" variant="h3">
              {customer.birthday &&
                format(
                  parse(customer.birthday, 'yyyy-MM-dd', new Date()),
                  'MMMM, dd, yyyy'
                )}
            </Typography>
          </div>
        </div>

        <div className={styles.info}>
          <Typography component="p" variant="label">
            Address
          </Typography>
          <button onClick={modalHandler} className={styles.address_button}>
            <Typography
              className={styles.delivery_address}
              component="p"
              variant="h3"
            >
              {get(deliveryData, 'address_line_1', '')},{' '}
              {get(deliveryData, 'city', '')}, {get(deliveryData, 'state', '')},{' '}
              {get(deliveryData, 'zipcode', '')}
              {get(deliveryData, 'unit_address', '')
                ? `, ${get(deliveryData, 'unit_address', '')}`
                : ''}
            </Typography>
            {!get(deliveryData, 'is_valid', true) && (
              <span className={styles.wrongAddress}>issue</span>
            )}
            <ArrowBottomIcon />
          </button>
        </div>
      </CardContent>
    </Card>
  );
}

export default CustomerDetails;
