import {useMemo, useRef} from 'react';
import {useInfiniteQuery} from 'react-query';
import {OrderService} from 'services/order.service';

export type OrderListFilter = {
  userId?: string;
  unAssignedOnly?: boolean;
  preference_date?: undefined;
  preference_time?: undefined | string;
  offset?: number;
  limit?: number;
  search?: string | undefined;
  filter?: any[];
  sortBy?: string;
};

const fetchData = async (filter: OrderListFilter, params: any) => {
  const offset = params.pageParam ? params.pageParam : 0;

  const orderService = new OrderService();
  const response = await orderService.getAllPaginated({
    ...filter,
    limit: 40,
    offset,
  });

  return {
    offset: offset + 40,
    count: response.data.count,
    results: response.data.results,
  };
};

const useOrderList = (filter: OrderListFilter = {}) => {
  const count = useRef(0);
  const {data, isLoading, fetchNextPage, isFetching, hasNextPage, remove} =
    useInfiniteQuery(
      ['orders', filter],
      (params) => fetchData(filter, params),
      {
        getNextPageParam: (lastPage) => {
          count.current = lastPage.count;

          return lastPage.results.length < 1 ? false : lastPage.offset;
        },
      }
    );

  const flattenedData = useMemo(
    () => (data ? data?.pages.flatMap((item) => item.results) : []),
    [data]
  );

  return {
    remove,
    orders: flattenedData,
    count: count.current,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetching,
  };
};

export default useOrderList;
