import {Switch, Route, Redirect, useLocation} from 'react-router-dom';
import cn from 'classnames';

import Sidebar from 'components/Sidebar';

import Support from 'pages/Support';
import DeliveryPlanner from 'pages/DeliveryPlanner';
import InstorePickups from 'pages/InstorePickups';
import Deliveries from 'pages/Deliveries';
import Settings from 'pages/Settings';
import RequestDetails from 'pages/RequestDetails';
import Requests from 'pages/Requests';
import EmployeeForm from 'pages/EmployeeForm';
import VehicleForm from 'pages/VehicleForm';
import UserDetails from 'pages/UserDetails';
import UserForm from 'pages/UserForm';
import Routes from 'pages/Settings/Routes';

import {CustomRouteProps} from 'components/CustomRoute';
import useUi from 'hooks/useUi';
import Products from 'pages/Products';
import {Toaster} from 'react-hot-toast';
import RouteHistory from 'pages/RouteHistory';
import Transactions from 'pages/Transactions';
import TransactionDetails from 'pages/TransactionDetails';

const mainRoutes: CustomRouteProps[] = [
  {path: '/support', component: Support, exact: true},
  {path: '/support/page/:page', component: Support, exact: true},
  {path: '/deliveries/delivery_planner', component: DeliveryPlanner},
  {path: '/deliveries/in-store_pickups', component: InstorePickups},
  {path: '/deliveries', component: Deliveries},
  {path: '/products/page/:page', component: Products},
  {path: '/products', component: Products},
  {path: '/settings/employees/new', component: EmployeeForm},
  {path: '/settings/employees/:employeeId', component: EmployeeForm},
  {path: '/settings/vehicles/new', component: VehicleForm},
  {path: '/settings/vehicles/:vehicleId', component: VehicleForm},
  {path: '/settings/users/:userId/edit', component: UserForm},
  {path: '/settings/users/:userId', component: UserDetails},
  {path: '/settings', component: Settings},
  {path: '/orders/:id', component: RequestDetails, exact: true},
  {path: '/requests/:id', component: RequestDetails, exact: true},
  {path: '/transactions/:id', component: TransactionDetails, exact: true},
  {
    path: '/requests/page/:page',
    component: () => <Requests source="app" />,
    exact: true,
  },
  {
    path: '/orders/page/:page',
    component: () => <Requests source="micromerchant" />,
    exact: true,
  },
  {path: '/requests', component: () => <Requests source="app" />, exact: true},
  {
    path: '/transactions',
    component: () => <Transactions />,
    exact: true,
  },
  {
    path: '/transactions/page/:page',
    component: () => <Transactions />,
    exact: true,
  },
  {
    path: '/orders',
    component: () => <Requests source="micromerchant" />,
    exact: true,
  },
  {path: '/history/routes/page/:page', component: Routes, exact: true},
  {path: '/history/routes/:id', component: RouteHistory, exact: true},
];

function MainApp() {
  const {sidebarExtended} = useUi();
  const {pathname} = useLocation();
  return (
    <>
      <Sidebar />
      <div
        className={cn('content', {
          '--sidebar-extended': sidebarExtended,
          '--fullsize': pathname.startsWith('/deliveries'),
        })}
      >
        <Switch>
          {mainRoutes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
          <Redirect to="/requests" />
        </Switch>
      </div>
      <Toaster />
    </>
  );
}

export default MainApp;
