import SearchInput from 'components/SearchInput';
import ListTabs from 'components/ListTabs';
import ListWrapper from 'components/ListWrapper';
import Typography from 'components/Typography';
import ListActionsWrapper from 'components/ListActionsWrapper';
import DataTable from 'components/DataTable';

import useSupport from './useSupport';
import Pagination from 'components/Pagination';
import {useEffect, useState} from 'react';
import {parseInt} from 'lodash';
import {useHistory, useRouteMatch} from 'react-router-dom';

const TABS = [
  {title: 'All', value: ''},
  {title: 'Active', value: 'active'},
  {title: 'Archived', value: 'archived'},
];

const SCHEMA = [
  {dataKey: 'number', header: 'Support number'},
  // {
  //   dataKey: 'new_leaf_order_number',
  //   header: 'Micro Merchant Order Number',
  // },
  {dataKey: 'created_at', header: 'Date & Time', sortable: true},
  {dataKey: 'customer', header: 'Patient', sortable: true},
  {dataKey: 'phone', header: 'Phone number'},
  {dataKey: 'status', header: 'Status', sortable: true},
];

function Suport() {
  const history = useHistory();
  const {params} = useRouteMatch<{page: string | undefined}>();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(parseInt(params.page || '1'));

  const {
    data,
    isLoading,
    totalPages,
    status,
    sortBy,
    sortOrder,
    setStatus,
    onSort,
  } = useSupport({page: currentPage - 1, search});

  useEffect(() => {
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params.page]);

  return (
    <>
      <Typography variant="h2">Support</Typography>
      <ListWrapper>
        <ListTabs
          isSupportPage={true}
          tabs={TABS}
          activeTabValue={status}
          onChange={setStatus}
        />
        <ListActionsWrapper>
          <SearchInput
            value={search}
            onChange={(val) => {
              setSearch(val.target.value);
              setCurrentPage(1);
              if (params.page !== '1') {
                history.push('/support/page/1');
              }
              sessionStorage.setItem('searchKey', val.target.value);
            }}
            placeholder="Search"
          />
        </ListActionsWrapper>
        <DataTable
          schema={SCHEMA}
          data={data}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSort={onSort}
          loading={isLoading}
        />
        <Pagination
          basePath="/support"
          page={currentPage}
          total={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </ListWrapper>
    </>
  );
}

export default Suport;
