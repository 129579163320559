import {Status, StatusColor, MMSubStatus} from 'types';

export const mapStatusColor: Record<Status, StatusColor> = {
  new: 'default',
  processing: 'default',
  processed: 'default',
  awaiting: 'yellow',
  active: 'default',
  cancelled: 'gray',
  in_progress: 'iris_dark',
  invalid: 'iris',
  completed: 'green',
  incomplete: 'gray',
  call_doctor: 'fuschia',
};

export const mapMMSubStatusColor: Record<MMSubStatus, StatusColor> = {
  completed: 'default',
  waiting_for_pickup: 'yellow',
  billed: 'iris',
  paid: 'iris',
  filled: 'iris_light',
  otc_status: 'default',
  delivered: 'green',
  ready_for_delivery: 'iris_dark',
  cancel_script: 'gray',
  picked_up: 'green',
  delivery_partner: 'iris',
  archive: 'gray',
};

export const mapStatusText: Record<Status, string> = {
  new: 'New',
  in_progress: 'In progress',
  invalid: 'Invalid',
  processing: 'default',
  processed: 'default',
  cancelled: 'default',
  awaiting: 'default',
  active: 'default',
  completed: 'Closed',
  incomplete: 'Incomplete',
  call_doctor: 'Call doctor',
};
export const mapMMStatusText: Record<MMSubStatus, string> = {
  completed: 'Unbilled',
  billed: 'Billed',
  paid: 'Paid',
  filled: 'Filled',
  otc_status: 'OTC CheckOut',
  ready_for_delivery: 'Ready for delivery',
  waiting_for_pickup: 'Waiting for Pickup',
  delivered: 'Delivered',
  picked_up: 'Picked Up',
  cancel_script: 'Canceled',
  delivery_partner: 'Delivery Partner',
  archive: 'Archived',
};
