import { Role } from "types";

const roles: { value: Role; label: string }[] = [
  { value: "pharmacy_technician", label: "Pharmacy Technician" },
  { value: "dispatcher", label: "Dispatcher" },
  { value: "driver", label: "Driver" },
  { value: "customer_service", label: "Customer Service" },
  { value: "management", label: "Management" },
  { value: "tech", label: "Tech" },
  { value: "front_desk", label: "Front-desk" },
];

export default roles;
