import {format, parseISO} from 'date-fns';

import Typography from 'components/Typography';

import {File} from 'types';

import styles from './Note.module.scss';
import Avatar from 'components/Avatar';
import {useHistory} from 'react-router-dom';

interface Props {
  text: string;
  created_at: string;
  author: string;
  avatar?: File;
  route_id?: number | null;
  order_id?: number | null;
  isCompleted: boolean;
}

function Note({
  text,
  author,
  avatar,
  created_at,
  route_id,
  order_id,
  isCompleted,
}: Props) {
  const history = useHistory();

  const lasttext = text.includes('Transaction ID')
    ? text.slice(0, text.lastIndexOf('Transaction ID: ') + 15)
    : text;

  return (
    <div className={styles.root}>
      <div className={styles.row_name}>
        <Typography variant="p">{lasttext}</Typography>
        {text.includes('Transaction ID') && (
          <Typography
            onClick={() =>
              history.push(
                `/transactions/${text.split('Transaction ID: ')[1]}`,
                {
                  isRouteHistory: true,
                }
              )
            }
            className={styles.transaction_id}
            variant="p"
          >
            {text.split('Transaction ID: ')[1]}
          </Typography>
        )}
        {isCompleted && (
          // <NavLink to={`/deliveries/delivery_planner?id=${route_id}`}>
          <>
            <Typography
              onClick={() => {
                history.push(`/history/routes/${route_id}`, {
                  isRouteHistory: true,
                });
              }}
              className={styles.route_id}
              variant="p"
            >
              See route
            </Typography>
            <Typography
              onClick={() => {
                history.push(`/history/routes/${route_id}`, {
                  showPdf: true,
                  order_id,
                });
              }}
              className={styles.route_id}
              variant="p"
            >
              See Delivery PDF
            </Typography>
          </>
          // </NavLink>
        )}
      </div>
      <div className={styles.bottom}>
        <div className={styles.author}>
          <Avatar name={author} file={avatar} size="sm" />
          <span className={styles.author__name}>{author}</span>
        </div>
        <span className={styles.datetime}>
          {format(parseISO(created_at), 'MMMM d Y, h:mm aa')}
        </span>
      </div>
    </div>
  );
}

export default Note;
