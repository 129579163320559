import {useHistory} from 'react-router';

import SearchInput from 'components/SearchInput';
import ListTabs from 'components/ListTabs';
import ListWrapper from 'components/ListWrapper';
import Typography from 'components/Typography';
import ListActionsWrapper from 'components/ListActionsWrapper';
import DataTable from 'components/DataTable';
import {DataItem} from 'types';
import styles from './Requests.module.scss';
import calendar from '../DeliveryPlanner/icons/calendar.svg';
import {DataTableSchemaItem} from 'types';
import useRequests from './useRequests';
import {useCallback, useEffect, useState} from 'react';
import Pagination from 'components/Pagination';
import {useRouteMatch} from 'react-router-dom';
import DateTimePicker from 'components/DateTimePicker';
import Select from 'components/Select';
import {components} from 'react-select';
import {get} from 'lodash';

const MM_SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'transaction_id',
    header: 'ID',
    sortable: true,
    index: 0,
  },
  {dataKey: 'amount', header: 'Amount', sortable: true, index: 1},
  {
    dataKey: 'transaction_date',
    header: 'Date',
    sortable: false,
    align: 'center',
    index: 2,
  },
  {
    dataKey: 'transaction_origin',
    header: 'Source',
    align: 'center',
    sortable: true,
    index: 3,
  },
  {dataKey: 'list_rx', header: 'List of RXs', sortable: false, index: 5},
  {dataKey: 'list_orders', header: 'List of Orders', sortable: false, index: 6},
];

function Requests() {
  const {params} = useRouteMatch<{page: string | undefined}>();
  const [currentPage, setCurrentPage] = useState(parseInt(params.page || '1'));
  const {
    data,
    totalPages,
    dailyCount,
    loading,
    sortBy,
    sortOrder,
    tabs,
    status,
    onSort,
    setStatus,
    keyWord,
    setKeyWord,
    onChangeData,
    date,
    setCreditType,
    creditType,
    totalAmount,
    startDate,
    endDate,
  } = useRequests({page: currentPage - 1});
  const history = useHistory();

  useEffect(() => {
    if (params.page) {
      setCurrentPage(parseInt(params.page));
    }
  }, [params.page]);

  const handleNavigateRequest = useCallback(
    (row: DataItem) => {
      if (get(row, 'service_name', '') === 'CreditSale') {
        history.push(`/transactions/${row.id}`);
      }
    },
    [history]
  );

  return (
    <>
      <div className={styles.top_header}>
        <Typography textAlign="right" variant="h2">
          Transactions
        </Typography>
        <div className={styles.count_wrapper}>
          <Typography textAlign="right" variant="h4">
            Count: {dailyCount} {'  |  '} Total: ${totalAmount || 0}
          </Typography>
        </div>
      </div>
      <ListWrapper>
        <ListTabs
          onChange={setStatus}
          isSupportPage={false}
          activeTabValue={status}
          tabs={tabs}
        />
        <ListActionsWrapper>
          <div className={styles.row}>
            <SearchInput
              className={styles.input}
              value={keyWord}
              onChange={(val) => {
                setKeyWord(val.target.value);
                setCurrentPage(1);
                history.push('/transactions/page/1');
                sessionStorage.setItem(
                  `searchKey_transaction`,
                  val.target.value
                );
              }}
              placeholder="Search"
            />
          </div>
          <div className={styles.preferenceDate}>
            <DateTimePicker
              icon={calendar}
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              showTimeSelect={false}
              dateFormat="MM/dd/yy E"
              value={date}
              placeholder="Transaction Date"
              customInput={styles.customInput}
              onChange={(data) => {
                setCurrentPage(1);
                history.push('/transactions/page/1');
                onChangeData(data);
              }}
            />
            <Select
              className={styles.selectCon}
              isSearchable={false}
              onChange={(val: any) => {
                setCreditType(val);
                setCurrentPage(1);
                history.push('/transactions/page/1');
                sessionStorage.setItem('creditType', JSON.stringify(val));
              }}
              options={[
                {label: 'Sale', value: 'CreditSale'},
                {label: 'Account Verify', value: 'CreditAccountVerify'},
              ]}
              value={creditType}
              placeholder={
                <span className="select-placeholder-text">Credit Type</span>
              }
              isClearable
              components={{
                Input: () => null,
                DropdownIndicator: (props) =>
                  !creditType ? (
                    <components.DropdownIndicator {...props} />
                  ) : null,
                Control: ({children, ...props}) => (
                  <components.Control {...props} className="select-row">
                    {children}
                  </components.Control>
                ),

                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </ListActionsWrapper>
        <DataTable
          schema={MM_SCHEMA}
          data={data}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onClick={handleNavigateRequest}
          onSort={onSort}
          loading={loading}
        />
        {!loading && (
          <Pagination
            basePath={'/transactions'}
            page={currentPage}
            total={totalPages}
            setCurrentPage={setCurrentPage}
          />
        )}
      </ListWrapper>
    </>
  );
}

export default Requests;
