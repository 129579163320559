import Button from 'components/Button';
import FormGroup from 'components/FormGroup';
import Modal from 'components/Modal';
import SelectField from 'components/SelectField';
import Typography from 'components/Typography';
import {SendRouteDto} from 'dto/send-route.dto';
import useDrivers from 'hooks/useDrivers';
import {Route} from 'pages/DeliveryPlanner/useDeliveryPlanner';
import useVehicles from 'pages/Settings/Vehicles/useVehicles';
import React, {useCallback, useEffect, useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import styles from './SendRouteModal.module.scss';

type SendRouteModalProps = {
  isOpen: boolean;
  route: Route;
  onClose: () => void;
  onSubmit: (route: Route) => Promise<any>;
};

const SendRouteModal: React.VFC<SendRouteModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  route,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: {isSubmitting},
  } = useForm<SendRouteDto>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      driver: undefined,
      vehicle: undefined,
    },
  });
  const {vehicles = [], isLoading: isLoadingVehicles} = useVehicles();
  const {drivers, isLoading: isLoadingDrivers} = useDrivers();

  const vehiclesOptions = useMemo(() => {
    return vehicles.map((vehicle) => ({
      value: vehicle.id,
      label: vehicle.model,
    }));
  }, [vehicles]);

  const driversOptions = useMemo(() => {
    return drivers.map((driver) => ({
      value: driver.id,
      label: driver.full_name || driver.email,
    }));
  }, [drivers]);

  const handleSubmitForm = useCallback(
    async (data: SendRouteDto) => {
      const driver = drivers.find((driver) => data.driver?.value === driver.id);
      const vehicle = vehicles.find(
        (vehicle) => data.vehicle?.value === vehicle.id
      );

      await onSubmit({
        ...route,
        driver: driver || route.driver,
        vehicle: vehicle || route.vehicle,
      });

      onClose();
    },
    [drivers, onClose, onSubmit, route, vehicles]
  );

  useEffect(() => {
    reset({
      driver: route.driver
        ? {
            value: route.driver.id,
            label:
              route.driver.full_name ||
              `${route.driver.first_name} ${route.driver.last_name}` ||
              '',
          }
        : undefined,
      vehicle: route.vehicle
        ? {
            value: route.vehicle.id,
            label: route.vehicle.nickname || route.vehicle.model,
          }
        : undefined,
    });
  }, [isOpen, reset, route, route.driver, route.vehicle]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.header}>
        <div className={styles.header__title}>
          <Typography variant="h2">Send route</Typography>
          <Typography variant="p">Who should we sent this route to?</Typography>
        </div>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(handleSubmitForm)}>
        <FormGroup>
          <Controller
            name="vehicle"
            control={control}
            rules={{required: true}}
            render={({field, fieldState}) => (
              <SelectField
                isDisabled
                label="Assigned vehicle"
                placeholder="Click to select"
                isLoading={isLoadingVehicles}
                options={vehiclesOptions}
                error={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="driver"
            control={control}
            rules={{required: true}}
            render={({field, fieldState}) => (
              <SelectField
                isDisabled
                label="Assigned driver"
                placeholder="Click to select"
                isLoading={isLoadingDrivers}
                options={driversOptions}
                error={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </FormGroup>
        <div className={styles.actions}>
          <Button color="outlined" size="lg" onClick={onClose}>
            Close
          </Button>
          <Button
            type="submit"
            color="green"
            size="lg"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Confirm
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default SendRouteModal;
